

import {ref} from 'vue'
import {useToast} from "primevue/usetoast";
import moment from "moment";
import {RegisterServiceUpdate} from "@/models/registerServiceUpdate";
import ZaloRepository from "@/services/ZaloRepository";

export default {
  props: {
    id: String,
  },
  setup(props){
    const toast = useToast();
    const registerServiceUpdate = ref({} as RegisterServiceUpdate);
    const minDate = ref(new Date());

    const toTimestamp = (strDate) => {
      const dt = Date.parse(strDate);
      return dt / 1000;
    }
    const loadData = () => {
      ZaloRepository.registerServiceById(props.id)
          .then((response) => {
            registerServiceUpdate.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    }

    loadData();

    const update = () => {
      console.log("#######$$$$$$$$$$$$$$$$$ update: ");
      console.log("#######$$$$$$$$$$$$$$$$$ update registerServiceUpdate.value.timeBooking: " + registerServiceUpdate.value.timeBooking);
      console.log("#######$$$$$$$$$$$$$$$$$ update registerServiceUpdate.value.timeBooking formatDate: " + formatDate(registerServiceUpdate.value.timeBooking));
      registerServiceUpdate.value.timeBooking = formatDate(registerServiceUpdate.value.timeBooking);
      ZaloRepository.updateRegisterService(registerServiceUpdate.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Đặt lịch khám thành công',
              life: 2500
            })
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    }
    const valid = () => {
      return registerServiceUpdate.value.timeBooking;
    }
    const formatDateTime = (date) => {
      console.log("########################## formatDateTime: " + moment(String(date)).format('DD/MM/YYYY'));
      return moment(String(date)).format('DD/MM/YYYY');
    };
    const formatDate = (date) => {
      return moment(String(date)).format('YYYY-MM-DDTHH:mm:ss');
    };

    return {
      registerServiceUpdate,
      update,
      valid,
      minDate,
      formatDateTime,
      // nonAccentVietnamese
    }
  }

}
