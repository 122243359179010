import Repository from './Repository'
import { AxiosResponse } from 'axios';
import {Message} from "@/models/message";
import {MessageWithAttachment} from "@/models/messageWithAttachment";
import Province from "@/models/province.models";
import {ListImage} from "@/models/listImage";
import {RegisterServiceUpdate} from "@/models/registerServiceUpdate";
import {Team} from "@/models/team.models";
import {Employee} from "@/models/employee";
import {EmployeeCreate} from "@/models/employeeCreate";
import {EmployeeUpdate} from "@/models/employeeUpdate";
import {GroupCreate} from "@/models/groupCreate";
import {ReceiverReportCreate} from "@/models/receiverReportCreate";
import {MemberInGroupCreate} from "@/models/memberInGroupCreate";
import {ManagerOfGroupCreate} from "@/models/managerOfGroupCreate";
import {KeToan} from "@/models/keToan";
import {ReceiverNotificationFallCreate} from "@/models/receiverNotificationFallCreate";
import {EmployeeCheckIn} from "@/models/employeeCheckIn";
import {Department} from "@/models/department";
import {EmployeeLogin} from "@/models/employeeLogin";

const resource = '/webhook'

export default new class {
  sendMessage(mess: Message): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/SendMessage`, mess);
  }
  sendMessageWithAttachmentList(mess: MessageWithAttachment): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/SendMessageWithAttachment`, mess);
  }
  getListCustomer(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listCustomer`);
  }
  getProvince(): Promise<AxiosResponse<Province[]>>{
    return Repository.get<Province[]>(`${resource}/listProvince`);
  }
  getListImage(): Promise<AxiosResponse<ListImage[]>>{
    return Repository.get<ListImage[]>(`${resource}/listImage`);
  }
  getListRegister(timeStart: any, timeEnd: any, kind: any): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listRegisterService/${timeStart}/${timeEnd}/${kind}`);
  }
  deleteImageFile(list: ListImage[]): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/deleteImage`, list);
  }
  updateRegisterService(registerServiceUpdate: RegisterServiceUpdate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/updateRegisterService`, registerServiceUpdate);
  }
  registerServiceById(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/registerService/${id}`);
  }
  createEmployee(employeeCreate: EmployeeCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createemployee`, employeeCreate);
  }
  updateEmployee(employeeUpdate: EmployeeUpdate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/updateemployee`, employeeUpdate);
  }
  deleteEmployee(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteemployee/${id}`);
  }
  getListEmployee(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getlistemployee`);
  }
  employeeById(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getemployeebyid/${id}`);
  }
  getListCustomerWithOutEmployee(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listCustomerWithOutEmployee`);
  }
  createReceiverReport(receiverReportCreate: ReceiverReportCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createReceiverReport`, receiverReportCreate);
  }
  deleteReceiverReport(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteReceiverReport/${id}`);
  }
  getListReceiverReport(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listReceiverReport`);
  }
  createGroup(groupCreate: GroupCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createGroup`, groupCreate);
  }
  deleteGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteGroup/${id}`);
  }
  listGroup(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listGroup`);
  }
  createMemberInGroup(memberInGroupCreate: MemberInGroupCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createMemberInGroup`, memberInGroupCreate);
  }
  deleteMemberInGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteMemberInGroup/${id}`);
  }
  listMemberInGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listMemberInGroup/${id}`);
  }
  createManagerOfGroup(managerOfGroupCreate: ManagerOfGroupCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createManagerOfGroup`, managerOfGroupCreate);
  }
  deleteManagerOfGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteManagerOfGroup/${id}`);
  }
  listManagerOfGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listManagerOfGroup/${id}`);
  }
  getListDayOff(timeFrom: number, timeTo: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listDayOff/${timeFrom}/${timeTo}`);
  }
  createKeToan(keToan: KeToan): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createKeToan`, keToan);
  }
  deleteKeToan(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteKeToan/${id}`);
  }
  getListKeToan(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListKeToan`);
  }
  createReceiverNotificationFallCreate(receiverNotificationFallCreate: ReceiverNotificationFallCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createReceiverNotificationFall`, receiverNotificationFallCreate);
  }
  deleteReceiverNotificationFall(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteReceiverNotificationFall/${id}`);
  }
  getListReceiverNotificationFall(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListReceiverNotificationFall`);
  }
  createEmployeeInHospital(employeeCheckIn: EmployeeCheckIn): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createEmployeeInHospital`, employeeCheckIn);
  }
  createDepartment(department: Department): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createDepartment`, department);
  }
  getListDepartments(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListDepartments`);
  }
  getListEmployeeInHospital(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListEmployeeInHospital`);
  }
  createEmployeeLogin(employeeLogin: EmployeeLogin): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createEmployeeLogin`, employeeLogin);
  }
  getListEmployeeLogin(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListEmployeeLogin`);
  }
}
