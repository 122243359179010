<template>
  <div v-if="isLoggedIn">
  <div className="on_page">
    <Toast/>
    <TopNavBar :isLoggedIn="isLoggedIn"/>
    <div className="main-content">
      <router-view/>
    </div>
  </div>
  </div>
  <div v-else>
    <Toast />
    <LoginPage />
  </div>
</template>

<style lang="scss">
.app-container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  height: inherit;
  background: #F7F8F9;

  .app-content {
    margin: 8px;
  }
}
</style>
<script>
import TopNavBar from "@/components/TopNavBar";
import LoginPage from "@/pages/LoginPage";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  components: {TopNavBar, LoginPage},
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => !!store.state.token);

    return {
      isLoggedIn
    }
  }
}
</script>
