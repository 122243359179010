

  import { computed, ref } from 'vue'
  import ZaloRepository from "@/services/ZaloRepository";
  import {ListImage} from "@/models/listImage";
  import {useToast} from "primevue/usetoast";
  import axios from "axios";
  import {FileDetail} from "@/models/fileDetail.models";
  import {useStore} from "vuex";
  import {useRouter} from "vue-router";

  export default {
      props: {
          id: String,
      },
    setup(props) {
        const products = ref([] as ListImage[]);
      const layout = ref('grid');
      const toast = useToast();
      const show = ref(false);
      const store = useStore();
      const router = useRouter();

      if(!(!!store.state.token)){
        router.push({
          name: 'home'
        });
      }
      const getListImage = () => {
        ZaloRepository.getListImage()
            .then((response) => {
              products.value = response.data;
            })
            .catch(err => {
              // toast.add({
              //   severity: 'error',
              //   summary: 'Lỗi',
              //   detail:'Lỗi khi tải địa điểm',
              //   life: 2000
              // });
            });
      }
      const deleteFile = (name: string) =>
      {
        listFileName.value.findIndex(x => x.nameFile == name);
        listFileName.value.splice(listFileName.value.findIndex(x => x.nameFile == name), 1);
      };
      getListImage();
      const listLink = ref([] as string[]);
      let listFileName = ref([] as FileDetail[]);
      const loadingBar = ref(false);
      const fileChange = (fileList) => {
        var leng = fileList.length;
        for (var i = 0; i < leng; i++)
        {
          var filetemp = ref({} as FileDetail);
          filetemp.value.nameFile = fileList[i].name;
          filetemp.value.value = fileList[i];
          listFileName.value.push(filetemp.value);
        }
      };
      const upload = () => {
        {
          listLink.value = [];
          var files = new FormData();
          listFileName.value.forEach(x =>
          {
            files.append("formFiles", x.value, x.nameFile);
          });
          loadingBar.value = true;
          axios.post(`/api/file/Upload`, files,
              {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'multipart/form-data'
                }
              }).then(response => {
                  loadingBar.value = false;
                  listFileName.value = [];
                  toast.add({
                    severity: 'success',
                    summary: 'Thành công',
                    detail:'Tải ảnh lên thành công',
                    life: 2000
                  });
          })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })});
        }
      };
      const imageClick = (index) => {
        console.log("############$$$$$$$$$$$$$$$$$$$$$ imageClick: " + index);
        show.value = false;
      }
      const showListImage = () => {
        show.value = true;
      }

      return {
        products,
        layout,
        upload,
        loadingBar,
        listFileName,
        fileChange,
        imageClick,
        show,
        showListImage,
        deleteFile
      }
    }

  }
