import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import Home from "@/pages/Home.vue";
import SendMessagePage from "@/pages/SendMessagePage.vue";
import UploadImage from "@/pages/UploadImage.vue";
import ShowListRegisterService from "@/pages/ShowListRegisterService.vue";
import UpdateRegisterService from "@/pages/UpdateRegisterService.vue";
import RegisterUserPage from "@/pages/RegisterUserPage.vue";
import AddEmployeePage from "@/pages/AddEmployeePage.vue";
import showListEmployee from "@/pages/ShowListEmployee.vue";
import updateEmployeePage from "@/pages/UpdateEmployeePage.vue";
import addGroupAndMemberPage from "@/pages/AddGroupAndMemberPage.vue";
import addReceiverReportPage from "@/pages/AddReceiverReportPage.vue";
import ManagerGroupPage from "@/pages/ManagerGroupPage.vue";
import ShowListDayOff from "@/pages/ShowListDayOff.vue";
import AddAccountantPage from "@/pages/AddAccountantPage.vue";
import AddReceiverFallPage from "@/pages/AddReceiverFallPage.vue";
import checkInGroupPage from "@/pages/CheckInGroupPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/checkInGroup',
    name: 'checkInGroup',
    component: checkInGroupPage,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/addReceiverReportPage',
    name: 'addReceiverReportPage',
    component: addReceiverReportPage,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/managerGroup',
    name: 'managerGroup',
    component: ManagerGroupPage,
    props: true,
  },
  {
    path: '/addemployee',
    name: 'addemployee',
    component: AddEmployeePage,
    props: true
  },
  {
    path: '/registeruser',
    name: 'registeruser',
    component: RegisterUserPage,
    props: true
  },
  {
    path: '/sendmessage',
    name: 'sendmessage',
    component: SendMessagePage,
    props: true
  },
  {
    path: '/uploadImage',
    name: 'uploadImage',
    component: UploadImage,
    props: true
  },
  {
    path: '/listRegisterService',
    name: 'listRegisterService',
    component: ShowListRegisterService,
    props: true
  },
  {
    path: '/updateregisterservice/:id',
    name: 'updateregisterservice',
    component: UpdateRegisterService,
    props: true
  },
  {
    path: '/showlistemployee',
    name: 'showlistemployee',
    component: showListEmployee,
    props: true
  },
  // {
  //   path: '/addGroup',
  //   name: 'addGroup',
  //   component: addGroupAndMemberPage,
  //   props: true
  // },
  {
    path: '/ShowListDayOff',
    name: 'ShowListDayOff',
    component: ShowListDayOff,
    props: true,
  },
  {
    path: '/addAccountantPage',
    name: 'AddAccountantPage',
    component: AddAccountantPage,
    props: true,
  },
  {
    path: '/addReceiverFallPage',
    name: 'AddReceiverFallPage',
    component: AddReceiverFallPage,
    props: true,
  },
  {
    path: '/updateemployee/:id',
    name: 'updateemployee',
    component: updateEmployeePage,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
