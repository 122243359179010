

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {ListCustomer} from "@/models/listCustomer";
import ZaloRepository from "@/services/ZaloRepository";
import {EmployeeCreate} from "@/models/employeeCreate";
import zaloRepository from "@/services/ZaloRepository";
import {GroupCreate} from "@/models/groupCreate";
import {Group} from "@/models/group";
import {MemberInGroupCreate} from "@/models/memberInGroupCreate";
import {MemberInGroup} from "@/models/memberInGroup";
import {ManagerOfGroupCreate} from "@/models/managerOfGroupCreate";
import {ManagerOfGroup} from "@/models/managerOfGroup";
import {Department} from "@/models/department";
import {EmployeeCheckIn} from "@/models/employeeCheckIn";
import {EmployeeLogin} from "@/models/employeeLogin";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const group = ref({} as GroupCreate);
    const department = ref({} as Department);
    const listDepartment = ref([] as Department[]);
    const member = ref({} as MemberInGroupCreate);
    const listMember = ref([] as MemberInGroup[]);
    const manager = ref({} as ManagerOfGroupCreate);
    const listManager = ref([] as ManagerOfGroup[]);
    const listCustomer = ref([] as ListCustomer[]);
    const employeeCheckin = ref({} as EmployeeCheckIn);
    const listEmployee = ref([] as EmployeeCheckIn[]);
    const employeeLogin = ref({} as EmployeeLogin);
    const listEmployeeLogin = ref([] as EmployeeLogin[]);

    const getListDepart = () => {
      ZaloRepository.getListDepartments()
          .then((response) => {
            listDepartment.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const getListEmployee = () => {
      ZaloRepository.getListEmployeeInHospital()
          .then((response) => {
            listEmployee.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const getListEmployeeLogin = () => {
      ZaloRepository.getListEmployeeLogin()
          .then((response) => {
            listEmployeeLogin.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    getListEmployee();
    getListDepart();
    getListEmployeeLogin();

    const createDepartment = () => {
      ZaloRepository.createDepartment(department.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Tạo khoa/phòng',
              detail: 'Tạo khoa/phòng thành công',
              life: 2000
            });
            getListDepart();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const createEmployeeLogin = () => {
      listEmployee.value.forEach(x => {
        if(x.phoneNumber == employeeLogin.value.phoneNumber){
          employeeLogin.value.fullname = x.fullName;
        }
      })
      ZaloRepository.createEmployeeLogin(employeeLogin.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Tạo tài khoản',
              detail: 'Tạo tài khoản thành công',
              life: 2000
            });
            getListEmployeeLogin();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const createEmployee = () => {
      listDepartment.value.forEach(x => {
        if(x.id == employeeCheckin.value.idDepartments){
          employeeCheckin.value.departmentsName = x.name;
        }
      })

      ZaloRepository.createEmployeeInHospital(employeeCheckin.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Tạo nhân viên',
              detail: 'Tạo nhân viên thành công',
              life: 2000
            });
            getListEmployee();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    return {
      group,
      createDepartment,
      member,
      listMember,
      manager,
      listManager,
      listCustomer,
      department,
      listDepartment,
      employeeCheckin,
      createEmployee,
      listEmployee,
      employeeLogin,
      listEmployeeLogin,
      createEmployeeLogin
    }
  }
}

