
import {computed, ref} from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const appMenu = computed(() => {
      const menu = ref([
        {
          label:'Gởi tin nhắn',
          icon:'pi pi-fw pi-send',
          url: '/sendmessage'
        },
        {
          label:'Đăng hình ảnh',
          icon:'pi pi-fw pi-upload',
          url: '/uploadImage'
        },
        {
          label:'Nhân viên nhận báo cáo',
          icon:'pi pi-fw pi-bars',
          url: '/addReceiverReportPage'
        },
        {
          label:'Nhân viên nhận thông báo CK',
          icon:'pi pi-fw pi-dollar',
          url: '/addAccountantPage'
        },
        {
          label:'Nhân viên nhận TB té/ngã',
          icon:'pi pi-fw pi-megaphone',
          url: '/addReceiverFallPage'
        },
        // {
        //   label:'Thêm nhóm',
        //   icon:'pi pi-fw pi-plus-circle',
        //   url: '/addGroup'
        // },
        {
          label:'Danh sách đăng ký khám bệnh',
          icon:'pi pi-fw pi-list',
          url: '/listRegisterService'
        },
        {
          label:'Thêm nhân viên hỗ trợ',
          icon:'pi pi-fw pi-user-plus',
          url: '/addemployee'
        },
        {
          label:'Danh sách nhân viên',
          icon:'pi pi-fw pi-users',
          url: '/showlistemployee'
        },
        {
          label:'Quản lý nhóm',
          icon:'pi pi-fw pi-users',
          url: '/managergroup'
        },
        {
          label:'Quản lý nghỉ phép',
          icon:'pi pi-fw pi-list',
          url: '/showlistdayoff'
        }
      ]);
      const menuDefault = ref([
      ]);
      console.log("####@@@@@@@@@@@@@@@@ debug token" + store.state.token);
      if(!!store.state.token)
        return menu.value;
      else
        return menuDefault.value;
    });
    console.log("####@@@@@@@@@@@@@@@@ debug appMenu" + JSON.stringify(appMenu.value));
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }
    const gotoLoginPage = () => {
      router.push({
        name: 'login',
      });
    }

    return {
      appMenu,
      logout,
      gotoLoginPage
    }
  }
}
