

import { computed, ref } from 'vue'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import {ListCustomer} from "@/models/listCustomer";
import ZaloRepository from "@/services/ZaloRepository";
import {FilterMatchMode} from "primevue/api";
import {KeToan} from "@/models/keToan";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const customer = ref({} as ListCustomer);
    const teamId = ref("");
    const listCustomer = ref([] as ListCustomer[]);

    const keToan = ref({} as KeToan);
    const listKeToan = ref([] as KeToan[]);

    const valid = computed(()=> customer.value.name && customer.value.idMember);

const getList = () => {
  ZaloRepository.getListCustomer()
      .then((response) => {
        listCustomer.value = response.data;
        getListAccountant();
      })
      .catch(err => {
        toast.add({
          severity: 'error',
          summary: 'Lỗi',
          detail:'Lỗi khi tải danh sách khách hàng',
          life: 2000
        });
      });
}
    getList();

    const getListAccountant = () => {
      ZaloRepository.getListKeToan()
          .then((response) => {
            listKeToan.value = response.data;
            listKeToan.value.forEach(x => {
              listCustomer.value.filter(y => {
                if(y.id == x.idZalo) x.imageLink = y.imageUrl;
              })
            });
            console.log("#################$$$$$$$$$$$$$$$$$ listKeToan: " + JSON.stringify(listKeToan.value));
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách thành viên',
              life: 2000
            });
          });
    }


    const doRegister = () => {
      keToan.value.idZalo = customer.value.id;
      keToan.value.zaloName = customer.value.name;
      ZaloRepository.createKeToan(keToan.value)
                .then((response) => {
                  toast.add({
                    severity: 'success',
                    summary: 'Đăng ký',
                    detail: 'Đăng ký thành viên thành công vào hệ thống',
                    life: 2000
                  });
                  getListAccountant();
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const doDelete = (id: number) => {
      ZaloRepository.deleteKeToan(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Xoá',
              detail: 'Xoá thành viên thành công khỏi hệ thống',
              life: 2000
            });
            getListAccountant();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
      register,
      doRegister,
      valid,
      error,
      listCustomer,
      customer,
      teamId,
      listKeToan,
      doDelete,
      filters,
      clearFilter,
      initFilters,
    }
  }
}

